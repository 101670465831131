<template>
  <div class="article-form">
    <div class="row">
      <div class="col-md-8 basic_data">


        <div class="d-flex justify-content-between flex-wrap gap-2">
          <div class="form-group">
            <label for="url_title">عنوان إرشادي</label>
            <Field
                name="url_title"
                id="url_title"
                :value="details.url_title"
                @input="$emit('update', 'url_title', $event.target.value)"
                placeholder="عنوان إرشادي"
                class="form-control form-control-solid"
            />
          </div>

          <div class="form-group">
            <label for="title">عنوان المقال</label>
            <Field
                name="title"
                id="title"
                :value="details.title"
                @input="$emit('update', 'title', $event.target.value)"
                placeholder="عنوان إرشادي"
                class="form-control form-control-solid"
                :class="{'border-red' : errors.title}"
                :rules="isRequired"
            />
            <span class="text-error">{{ errors.title ? 'الرجاء ادخال عنوان المقال' : '' }}</span>

          </div>

          <div class="form-group">
            <label for="source">المصدر</label>
            <Field
                name="source"
                id="source"
                :value="details.source"
                @input="$emit('update', 'source', $event.target.value)"
                placeholder="المصدر"
                class="form-control form-control-solid"
            />
          </div>

          <div class="form-group tags-control">
            <label>الأوسمة</label>
            <el-select
                class="form-control position-relative multi-select"
                v-model="selected_tags"
                multiple
                allow-create
                filterable
                remote
                reserve-keyword
                default-first-option
                placeholder="Please enter a keyword"
                :remote-method="handleSearchTags"
                :loading="isTagsLoading"
                loading-text="جاري البحث ...">
              <el-option
                  v-for="item in tags"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="form-group flex-lg-grow-1 mt-3">
          <label for="summary">ملخص الخبر</label>
          <textarea
              rows="5"
              id="summary"
              :value="details.summary"
              @input="$emit('update', 'summary', $event.target.value)"
              name="summary"
              class="form-control w-100 input-rounded form-control-solid"
              placeholder="ملخص الخبر"
          ></textarea>
        </div>

        <div class="form-group mt-3 "
             :class="{'border-2 border border-danger' : contentError && !details.content.length}">
          <CustomCKEditor :content="details.content" @updateContent="val=>$emit('update','content',val)"/>
        </div>
        <span class="text-error">{{ contentError && !details.content.length ? 'الرجاء ادخال محتوى الخبر' : '' }}</span>
      </div>

      <div class="col-lg-4">
        <div class="form-group news-image">
          <label
              class="placeholder-image"
              @click="handleOpenUploadCenter"
          >
            <img
                class="w-100"
                height="220"
                v-if="photo"
                :src="APP_URL + photo"
                alt=""
            />
          </label>
          <Field
              name="image_description"
              id="image_description"
              :value="details.image_description"
              @input="$emit('update', 'image_description', $event.target.value)"
              placeholder="أدخل وصف الصورة هنا"
              class="form-control form-control-solid"
          />
        </div>

        <el-dialog
            @close="closeUploadCenter"
            :destroy-on-close="true"
            title="مركز الرفع"
            v-model="showUploadCenter"
        >
          <UploadCenter
              :is-single-upload="isSingleUpload"
              @save="handleAddPhoto"
          />
        </el-dialog>

        <div class="form-group mt-3 tags-control">
          <label>الكاتب</label>
          <el-select
              class="form-control position-relative multi-select"
              v-model="selected_authors"
              filterable
              remote
              reserve-keyword
              default-first-option
              placeholder="Please enter a keyword"
              :remote-method="handleSearchAuthors"
              :loading="isAuthorsLoading"
              loading-text="جاري البحث ...">
            <el-option
                v-for="item in authors"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>

        <div class="form-group text-center mt-4" dir="ltr">
          <label for="date" class="text-end d-block">التاريخ</label>
          <DatePicker mode="dateTime" v-model="date">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="date"
                  class="px-2 py-1 form-control text-end form-control-solid border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>

        <div class="form-group mt-3">
          <h5>التصنيفات</h5>
          <el-select filterable
                     name="category"
                     class="w-100  form-control-solid"
                     v-model="category_id"
                     :rules="isRequired"
                     :class="{'border-red' : errors.category}"
                     required
          >
            <el-option-group
                v-for="item in categories"
                :key="item.id"
                :label="item.name"
            >
              <el-option
                  v-for="subCategory in item.children"
                  :key="subCategory.id"
                  :label="subCategory.name"
                  :value="subCategory.id"
              >
              </el-option>
            </el-option-group>
          </el-select>
          <span class="text-error">{{ errors?.category ? 'الرجاء اختيار التصنيف' : '' }}</span>
        </div>


        <div class="checkbox-group mt-3">
          <input
              type="checkbox"
              id="twitter"
              name="twitter"
              :value="details.twitter"
              @input="
                $emit(
                  'update',
                  'is_shown_in_template',
                  !details.twitter
                )
              "
              :checked="details.twitter"

          />
          <label for="twitter"> نشر تلقائي على تويتر </label>
        </div>


        <div class="checkbox-group">
          <input
              type="checkbox"
              id="is_main_article"
              name="is_main_article"
              :value="details.is_main_article"
              @input="
                $emit(
                  'update',
                  'is_main_article',
                  !details.is_main_article
                )
              "
              :checked="details.is_main_article"
          />
          <label for="is_main_article"> ظهور ك خبر </label>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {Field} from "vee-validate";
import CustomCKEditor from "@/components/editor";
import UploadCenter from "@/components/uploadCenter";
import {fetchSearchTags} from "@/services/news";
import {DatePicker} from "v-calendar";
import {getAuthors} from "@/services/articles";
import {mapGetters} from "vuex";

export default {
  name      : "ArticleDetails",
  props     : ["details", "errors", "contentError"],
  components: {
    Field,
    UploadCenter,
    CustomCKEditor,
    DatePicker
  },
  data()
  {
    return {
      photo           : this.details.image || '',
      date            : this.details.date_to_publish || new Date(),
      tags            : [],
      isTagsLoading   : false,
      selected_tags   : [],
      authors         : this.details.author || [],
      isAuthorsLoading: false,
      selected_authors: this.details.author_id || '',
      isSingleUpload  : false,
      showUploadCenter: false,
      category_id     : this.details.category_id || ''
    }
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL;
    },
    ...mapGetters(['categories'])
  },
  watch   : {
    date(newVal)
    {
      this.$emit("update", "date_to_publish", newVal.toLocaleTimeString('en-US', {
        hour  : 'numeric',
        hour12: false,
        minute: 'numeric'
      }));
    },
    selected_tags(newVal)
    {
      this.$emit("update", "tags", newVal);
    },
    selected_authors(newVal)
    {
      this.$emit("update", "author", newVal);
    },
    category_id(newVal)
    {
      this.$emit("update", "category_id", newVal);

    }
  },

  methods: {
    isRequired(value)
    {
      return value ? true : 'This field is required';
    },
    handleSearchTags(val)
    {
      if (val)
      {
        this.isTagsLoading = true;
        fetchSearchTags(val).then((res) => {
          this.tags          = res.data;
          this.isTagsLoading = false;
        })
            .catch(() => this.isTagsLoading = false)
      }
    },
    handleSearchAuthors(key)
    {
      if (key.trim())
      {
        this.isAuthorsLoading = true;
        getAuthors(key)
            .then(res => {
              this.authors          = res.data;
              this.isAuthorsLoading = false
            })
            .catch(() => {
              this.isAuthorsLoading = false
            })
      }
    },
    handleOpenUploadCenter()
    {
      this.showUploadCenter = true;
      this.isSingleUpload   = true;
    },
    closeUploadCenter()
    {
      this.showUploadCenter = false;
      this.isSingleUpload   = false;
    },
    handleAddPhoto(photo)
    {
      if (this.showUploadCenter && this.isSingleUpload)
      {
        this.photo = photo.url;
        this.$emit("update", "image", photo.url);
      }
      this.closeUploadCenter();
    },
  },

}


</script>

<style lang="scss">
.article-form {

  .basic_data .tags-control,
  .basic_data .form-control-solid {
    width: 100%;
  }


  @media (min-width: 992px) {
    .basic_data .tags-control,
    .basic_data .form-control-solid {
      width: 450px;
    }
  }


  .tags-control {

    .form-control {
      padding: 0;
      border-color: transparent;
    }


    .el-input__inner {
      height: 42px !important;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.checkbox-group {

  [type=checkbox] {
    vertical-align: middle;
    margin-inline-end: 8px;
  }

  label {
    font-weight: normal;
  }
}


.multi-select {

  .el-select__input {
    margin: 0;
  }

  .el-select__tags,
  input {
    padding: 0 5px;
    max-width: 100% !important;
  }

  .el-input__inner {
    padding: 0 10px;
    min-height: 42px !important;
    width: 100%;
  }

  .el-select__tags .el-tag {
    padding: 2px 6px 2px 0;
  }

  .el-select__tags .el-tag--info {
    background-color: #15aec2;
    color: #fff;
  }

  .el-tag--default .el-tag__close {
    margin-right: 6px;
    align-self: end;
    background-color: rgba(255, 255, 255, 0.9);
    margin-bottom: 2px;

    :hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }


}


@media (max-width: 992px) {
  .basic_data {
    > div.d-flex {
      flex-direction: column;
    }
  }
}

</style>
