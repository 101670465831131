<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-md-5">

        <div class="form-group">
          <label for="social_title">عنوان المقال</label>
          <Field
              name="social_title"
              id="social_title"
              :value="socialData.social_title"
              @input="$emit('update', 'social_title', $event.target.value)"
              placeholder="عنوان المقال"
              class="form-control form-control-solid"
          />
          <span class="hint">* يوصى ان لا يتجاوز العنوان 60 حرفا</span>
        </div>

        <div class="form-group mt-3">
          <label for="social_description">وصف المقال</label>
          <textarea
              rows="5"
              :value="socialData.social_description"
              @input="$emit('update', 'social_description', $event.target.value)"
              name="social_description"
              id="social_description"
              class="form-control input-rounded form-control-solid"
              placeholder="وصف المقال"
          ></textarea>
          <span class="hint">* يجب ان لا يتجاوز وصف المقال 230 حرفا</span>
        </div>

      </div>

      <div class="col-md-3 mt-1">
        <div class="form-group">
          <label>الصورة</label>
          <label class="placeholder-image mt-0" @click="showUploadCenter=true;isSingleUpload=true">
            <img class="w-100" height="220" v-if="photo" :src="APP_URL + photo" alt="">
          </label>
        </div>
        <el-dialog   :destroy-on-close=true
                     title="مركز الرفع"
                     v-model="showUploadCenter"
        >
          <UploadCenter :is-single-upload="isSingleUpload" @save="handleAddPhoto"/>
        </el-dialog>

      </div>
    </div>
  </div>
</template>

<script>
import UploadCenter from "@/components/uploadCenter";
import {Field} from "vee-validate";

export default {
  name : "SocialMedia",
  props: ["socialData"],
  components: {
    Field,
    UploadCenter
  },
  data() {
    return {
      showUploadCenter: false,
      isSingleUpload: false,
      photo: this.socialData.social_image || ''
    }
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL
    },
  },
  methods: {
    closeUploadCenter() {
      this.showUploadCenter = false;
      this.isSingleUpload = false;
    },
    handleAddPhoto(photo) {
      if (this.showUploadCenter) {
        this.photo = photo.url;
        this.$emit('update','social_image',photo.url)
      }
      this.closeUploadCenter();
    }
  }
}
</script>
